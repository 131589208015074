import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';

import {Box, FormControl, FormHelperText, FormLabel, Typography} from '@mui/joy';
import {useState} from 'react';
import LoginLayout from '../layouts/LoginLayout';
import {APICertificate, validateCertificate} from '../state/accountApi';
import VerifiedRounded from '@mui/icons-material/VerifiedRounded';
import {Error} from '../layouts/RootLayout';

function formatDate(dateStr: string) {
    const date = new Date(dateStr);

    // Format the date
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', {month: 'long'});
    const year = date.getUTCFullYear();

    return `${day}. ${month} ${year}`;
}

function CertificateValidationPage({onError}: {onError: (e: Error) => void}) {
    const [certificateId, setCertificateId] = useState('');
    const [invalid, setInvalid] = useState(false);
    const [certificate, setCertificate] = useState<APICertificate | null>(null);

    const hasError = certificateId !== '' && certificateId.length !== 24;

    return (
        <LoginLayout title="Validate Course Certificate">
            {invalid && <Typography color="danger">No such certificate found.</Typography>}
            {!certificate && !invalid && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (certificateId.length !== 24) return;

                        validateCertificate(certificateId).then((r) => {
                            if (r.error) {
                                if (r.status === 404) setInvalid(true);
                                else onError({message: r.error});
                                return;
                            }

                            if (!r.certificate) return;
                            setCertificate(r.certificate);
                        });
                    }}
                >
                    <FormControl error={hasError}>
                        <FormLabel>Certificate ID</FormLabel>
                        <Input
                            placeholder="Certificate ID"
                            onChange={(e) => setCertificateId(e.target.value)}
                            value={certificateId}
                        />
                        {hasError && (
                            <FormHelperText>
                                Expecting exactly 24 characters.
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Button
                        type="submit"
                        sx={{marginTop: 1}}
                        disabled={certificate !== '' && hasError}
                    >
                        Validate
                    </Button>
                </form>
            )}
            {certificate && (
                <Box
                    sx={{
                        textAlign: 'center',
                        width: '100%',
                        paddingBottom: 4,
                    }}
                >
                    <Typography color="success" level="h3" sx={{paddingY: 3}}>
                        <VerifiedRounded /> Valid!
                    </Typography>
                    <Typography level="body-sm">This certificate was issued to</Typography>
                    <Typography level="body-lg">
                        {`${certificate.firstName} ${certificate.lastName}`}
                    </Typography>
                    <Typography level="body-sm">
                        {`on the ${formatDate(certificate.createdAt)} for completing`}
                    </Typography>
                    <Typography level="body-md">{certificate.courseName}</Typography>
                </Box>
            )}
        </LoginLayout>
    );
}

export default CertificateValidationPage;
