import React from 'react';
import Box from '@mui/joy/Box';
import Sidebar from '../Sidebar';
import {User} from '../state/accountApi';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import {Alert, IconButton} from '@mui/joy';
import {Outlet} from 'react-router-dom';

export interface Error {
    message: string;
}

function RootLayout({
    isLoggedIn,
    errors,
    userData,
    onLogout,
    onRemoveError,
}: {
    isLoggedIn: boolean;
    errors: Error[];
    userData?: User;
    onLogout: () => void;
    onRemoveError: (e: Error) => void;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            {
                /* Show sidebar only when logged in */
                isLoggedIn && userData !== undefined && (
                    <Sidebar user={userData} onLogout={onLogout} />
                )
            }
            {/* Main page content wrapper. */}
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    minHeight: '100dvh',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* Global error list sticks to the top and does not offset any following boxes */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        minWidth: '100%',
                    }}
                >
                    {
                        /* Global error list */
                        errors.map((e: Error) => (
                            <Alert
                                startDecorator={<WarningIcon />}
                                variant="soft"
                                color="danger"
                                endDecorator={
                                    <React.Fragment>
                                        <IconButton
                                            onClick={() => onRemoveError(e)}
                                            variant="soft"
                                            size="sm"
                                            color="danger"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            >
                                {e.message}
                            </Alert>
                        ))
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        minHeight: '100dvh',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

export default RootLayout;
