import React, {useEffect, useState} from 'react';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import {getCertificates, APICertificate, createCertificate} from '../state/accountApi';
import PageLayout from '../layouts/PageLayout';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import {Box, FormControl, FormLabel} from '@mui/joy';

const CreateCertificateForm = ({
    onCreate,
}: {
    onCreate: (newCert: APICertificate) => void;
}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [courseName, setCourseName] = useState('');

    const onFormSubmit = (event: {preventDefault: () => void}) => {
        event.preventDefault();
        createCertificate({firstName, lastName, courseName}).then(({certificate}) => {
            if (!certificate) return;
            onCreate(certificate);
        });
    };

    return (
        <form onSubmit={onFormSubmit}>
            <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', gap: 2}}>
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input
                        onChange={(evt) => {
                            setFirstName(evt.target.value);
                        }}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        onChange={(evt) => {
                            setLastName(evt.target.value);
                        }}
                    />
                </FormControl>
            </Box>
            <FormControl>
                <FormLabel>Course</FormLabel>
                <Input
                    onChange={(evt) => {
                        setCourseName(evt.target.value);
                    }}
                />
            </FormControl>
            <Button sx={{marginTop: 1}} onClick={onFormSubmit}>
                Create
            </Button>
        </form>
    );
};

function CertificatesPage() {
    const [certificates, setCertificates] = useState<APICertificate[]>([]);

    useEffect(() => {
        getCertificates().then((r) => {
            console.log(r);
            r.certificates && setCertificates(r.certificates);
        });
    }, [setCertificates]);

    return (
        <PageLayout title="Course Certificates">
            <Box padding={5}>
                <CreateCertificateForm
                    onCreate={(certificate) =>
                        setCertificates([...certificates, certificate])
                    }
                />
            </Box>

            <Sheet>
                <Table
                    borderAxis="xBetween"
                    color="neutral"
                    stickyFooter={false}
                    stickyHeader={false}
                    variant="plain"
                >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Course</th>
                            <th>ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {certificates.map((cert) => (
                            <tr>
                                <td>{cert.createdAt}</td>
                                <td>{cert.firstName}</td>
                                <td>{cert.lastName}</td>
                                <td>{cert.courseName}</td>
                                <td>{cert.id}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </PageLayout>
    );
}

export default CertificatesPage;
