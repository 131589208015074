import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

function PageLayout({children, title}: React.PropsWithChildren<{title: string}>) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'left',
                    minWidth: 320,
                    paddingTop: 5,
                }}
            >
                <Typography level="h1" sx={{paddingX: 2}}>
                    {title}
                </Typography>
                {children}
            </Box>
        </Box>
    );
}

export default PageLayout;
