import {extendTheme} from '@mui/joy/styles';

declare module '@mui/joy/styles' {
    // No custom tokens found, you can skip the theme augmentation.
}

export const smTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    '50': '#faedeb',
                    '100': '#f0cac2',
                    '200': '#e6a699',
                    '300': '#dc8270',
                    '400': '#d25f47',
                    '500': '#C84B31',
                    '600': '#d25f47',
                    '700': '#662619',
                    '800': '#3d170f',
                    '900': '#140805',
                },
            },
        },
        dark: {
            palette: {},
        },
    },
});

const primary = {
    '50': '#ffe5f5',
    '100': '#ffb3e0',
    '200': '#ff80cb',
    '300': '#ff4db7',
    '400': '#ff1aa2',
    '500': '#E8008A',
    '600': '#BD006E',
    '700': '#85004D',
    '800': '#4d002e',
    '900': '#1a000f',
};

export const wlTheme = extendTheme({
    radius: {
        xs: '8px', // Adjust the values as needed
        sm: '16px',
        md: '24px',
        lg: '32px',
    },
    colorSchemes: {
        light: {
            palette: {primary},
        },
        dark: {
            palette: {primary},
        },
    },
});
