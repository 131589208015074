import {FormEventHandler, useEffect, useState} from 'react';

import {passwordReset} from '../state/accountApi';

import {useNavigate} from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import TurnstileWidget from '../components/TurnstileWidget';
import {Page} from '../page';

function Verify() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [cfToken, setCfToken] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [token, setToken] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    useEffect(() => {
        const params = new URL(document.location.href).searchParams;
        const tokenFromQuery = params.get('token') || '';
        const emailFromQuery = params.get('email') || '';
        setToken(tokenFromQuery);
        setEmail(emailFromQuery);
    }, []);

    const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        if (password2 !== password) {
            setValidationMessage('Passwords do not match, please try again');
        } else {
            await passwordReset({email, password, token, cfToken});
            setValidationMessage('Password successfully changed!');
            await new Promise((resolve) => setTimeout(resolve, 10000));
            setValidationMessage('');
            navigate(Page.Login);
        }
    };

    return (
        <PageLayout title="Reset Password">
            <form onSubmit={onSubmit}>
                <Typography>{validationMessage}</Typography>
                <label>Email</label>
                <Input
                    id="email"
                    disabled={true}
                    value={email}
                    onChange={(evt) => setEmail(email)}
                />
                <label>Password</label>
                <Input
                    value={password}
                    onChange={(evt) => {
                        setPassword(evt.target.value);
                        setValidationMessage('');
                    }}
                    type={'password'}
                />
                <label>Repeat Password</label>
                <Input
                    value={password2}
                    onChange={(evt) => {
                        setPassword2(evt.target.value);
                        setValidationMessage('');
                    }}
                    type={'password'}
                />
                <Input
                    value={token}
                    onChange={(evt) => setPassword2(evt.target.value)}
                    type={'password'}
                    style={{display: 'none'}}
                />
                <TurnstileWidget setCfToken={setCfToken} />
                <Button type="submit" sx={{marginTop: 2, width: '100%'}}>
                    Save
                </Button>
            </form>
        </PageLayout>
    );
}

export default Verify;
