import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import {Page} from './page';
import {WonderlandIconReversed} from './icons/WonderlandIcon';
import {useNavigate, useNavigation} from 'react-router-dom';
import {User} from './state/accountApi';

export default function Sidebar({user, onLogout}: {user: User; onLogout: () => void}) {
    const navigate = useNavigate();
    const navigation = useNavigation();
    return (
        <Sheet
            className="Sidebar"
            sx={{
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
                left: 0,
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
            />
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                {process.env.REACT_APP_TARGET === 'sm' && (
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" width={72} />
                )}
                {process.env.REACT_APP_TARGET === 'wle' && (
                    <WonderlandIconReversed width={72} />
                )}
                <Typography level="title-lg">
                    {process.env.REACT_APP_TARGET === 'sm'
                        ? 'Story Machine Account'
                        : 'Wonderland Account'}
                </Typography>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    <ListItem onClick={() => navigate(Page.Account)}>
                        <ListItemButton
                            selected={navigation.location?.pathname === Page.Account}
                        >
                            <HomeRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">Account</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem onClick={() => navigate(Page.Tokens)}>
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            selected={navigation.location?.pathname === Page.Tokens}
                        >
                            <VpnKeyRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">Auth Tokens</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    {user.isAdmin && (
                        <>
                            <ListItem onClick={() => navigate(Page.Certificates)}>
                                <ListItemButton
                                    selected={
                                        navigation.location?.pathname === Page.Certificates
                                    }
                                >
                                    <VerifiedRoundedIcon />
                                    <ListItemContent>
                                        <Typography level="title-sm">
                                            Certificates
                                        </Typography>
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>
                        </>
                    )}
                </List>
            </Box>
            <Divider />
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level="title-sm">
                        {user.firstName + ' ' + user.lastName}
                    </Typography>
                    <Typography level="body-xs">{user.email}</Typography>
                </Box>
                {/* TODO: Properly logout here */}
                <IconButton
                    onClick={() => {
                        navigate(Page.Login);
                        onLogout();
                    }}
                    size="sm"
                    variant="plain"
                    color="neutral"
                >
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    );
}
