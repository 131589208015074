import Turnstile, {useTurnstile} from 'react-turnstile';
import React, {useEffect} from 'react';

if (!process.env.REACT_APP_CF_KEY) {
    throw new Error('Missing REACT_APP_CF_KEY. Did you configure your .env file?');
}

export default function TurnstileWidget({
    setCfToken,
}: {
    setCfToken: (token: string) => void;
}) {
    const turnstile = useTurnstile();

    useEffect(() => {
        if (turnstile) {
            try {
                turnstile.reset();
            } catch (err) {
                console.log('could not reset cf turnsile', err);
            }
        }
    }, [turnstile]);
    return (
        <Turnstile
            sitekey={process.env.REACT_APP_CF_KEY as string}
            onVerify={(token) => {
                console.log('token for cloudflare challenge set');
                setCfToken(token);
            }}
        />
    );
}
