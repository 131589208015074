import {FormEventHandler, useEffect, useState} from 'react';
import Input from '@mui/joy/Input';
import Checkbox from '@mui/joy/Checkbox';
import Button from '@mui/joy/Button';

import {updateUserData, User} from '../state/accountApi';
import PageLayout from '../layouts/PageLayout';

function UserPage({userData}: {userData?: User}) {
    const [company, setCompanyName] = useState(userData?.company ?? '');
    const [lastName, setLastName] = useState(userData?.lastName ?? '');

    const [firstName, setFirstName] = useState(userData?.firstName ?? '');
    const [receiveNewsletter, setReceiveNewsletter] = useState(
        userData?.receiveNewsletter ?? false
    );

    useEffect(() => {
        if (!userData) return;

        setCompanyName(userData.company);
        setLastName(userData.lastName);
        setFirstName(userData.firstName);
        setReceiveNewsletter(userData.receiveNewsletter);
    }, [userData, setCompanyName, setLastName, setFirstName, setReceiveNewsletter]);

    const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        await updateUserData(firstName, lastName, company, receiveNewsletter);
    };

    return (
        <PageLayout title="Account">
            <form onSubmit={onSubmit}>
                <label>Email</label>
                <Input id="email" disabled={true} value={userData?.email} />
                <label>First Name</label>
                <Input
                    value={firstName}
                    onChange={(evt) => setFirstName(evt.target.value)}
                />
                <label>Last Name</label>
                <Input value={lastName} onChange={(evt) => setLastName(evt.target.value)} />
                <label>Company</label>
                <Input
                    value={company}
                    onChange={(evt) => setCompanyName(evt.target.value)}
                />
                <Checkbox
                    checked={receiveNewsletter}
                    onChange={(evt) => setReceiveNewsletter(evt.target.checked)}
                    label="Receive Newsletter"
                ></Checkbox>
                <Button type="submit" sx={{marginTop: 2, width: '100%'}}>
                    Save
                </Button>
            </form>
        </PageLayout>
    );
}

export default UserPage;
