import {useEffect, useState} from 'react';

import {verify} from '../state/accountApi';

import {useNavigate} from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';

function Verify() {
    const navigate = useNavigate();
    const [validationMessage, setValidationMessage] = useState(
        'Checking the email validation...'
    );

    useEffect(() => {
        const params = new URL(document.location.href).searchParams;
        const token = params.get('token') || '';
        verify({
            token,
        })
            .then(async () => {
                setValidationMessage('Thank you for validating your email address.');
                await new Promise((resolve) => setTimeout(resolve, 5000));
                navigate('/');
            })
            .catch(async (e) => {
                setValidationMessage('There was an error for your email validation.');
                await new Promise((resolve) => setTimeout(resolve, 5000));
                navigate('/');
            });
    });

    return <PageLayout title="Verification">{validationMessage}</PageLayout>;
}

export default Verify;
