import React from 'react';

import {Box} from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import {WonderlandLogoReversed} from '../icons/WonderlandLogo';

function LoginLayout({children, title}: React.PropsWithChildren<{title: string}>) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100dvh',
            }}
        >
            {process.env.REACT_APP_TARGET === 'sm' && (
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" />
            )}
            {process.env.REACT_APP_TARGET === 'wle' && (
                <Box sx={{padding: 2, width: '100%'}}>
                    <WonderlandLogoReversed />
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Card variant="soft">
                    <Typography level="h1">{title}.</Typography>
                    <CardContent>{children}</CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default LoginLayout;
