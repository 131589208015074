import {ThreeCircles} from 'react-loader-spinner';

/**
 * Loader spinner component
 * @param firstMessage
 * @param secondMessage
 * @param progressMessage
 * @param currentProgress
 * @param maxProgress
 * @constructor
 */
export default function Loader({
    firstMessage,
    secondMessage,
    progressMessage,
    currentProgress,
    maxProgress,
}: {
    firstMessage?: string;
    secondMessage?: string;
    progressMessage?: string;
    currentProgress?: string;
    maxProgress?: string;
} = {}) {
    return (
        <div>
            {firstMessage || ''}
            <br></br>
            {secondMessage || ''}
            <div className="flex items-center justify-center p-10">
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor="#e8008a"
                    innerCircleColor="#27C98A"
                    middleCircleColor="#FFCD00"
                />
            </div>
            {progressMessage || ''}
            {'  '}
            {currentProgress !== undefined && maxProgress !== undefined
                ? `${currentProgress}/${maxProgress}`
                : ''}
        </div>
    );
}
